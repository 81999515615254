import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

import { updateOrderStatus } from "../api";

const OrdersTable = ({ fetchOrders, orders }) => {
  const navigate = useNavigate();
  const paginationModel = { page: 0, pageSize: 10 };
  const [selectionModel, setSelectionModel] = useState([]);

  // ************** MANAGE STATUS CHANGE ***************

  const [statusMap, setStatusMap] = useState(
    orders?.reduce((acc, curr) => {
      acc[curr._id] = curr.status;
      return acc;
    }, {}) || {}
  );

  // ************** TABLE COLUMNS ***************

  const columns = [
    { field: "id", headerName: "Order ID", width: 120 },
    { field: "userName", headerName: "Customer Name", width: 140 },
    { field: "totalAmount", headerName: "Total Amount", width: 130 },
    {
      field: "status",
      headerName: "Status",
      width: 180,
      renderCell: (params) => (
        <select
          value={statusMap[params.row.id]}
          onClick={(e) => e.stopPropagation()}
          className="w-full h-9 border rounded-md cursor-pointer"
          onChange={(e) => handleStatusChange(params.row.id, e.target.value)}
        >
          <option value="Booked">Booked</option>
          <option value="Scheduled for pickup">Scheduled for pickup</option>
          <option value="Arrived at Filato">Arrived at Filato</option>
          <option value="In process">In process</option>
          <option value="Finishing">Finishing</option>
          <option value="Out for delivery">Out for delivery</option>
          <option value="Delivered">Delivered</option>
        </select>
      ),
    },
    { field: "createdAt", headerName: "Placed On" },
    { field: "deliveryAddress", headerName: "Delivery Address", width: 250 },
    { field: "pickupAddress", headerName: "Pickup Address", width: 250 },
  ];

  // ************** TABLE ROWS ***************

  const rows = orders?.map((order) => ({
    id: order._id,
    userName: order.userId.name,
    totalAmount: order.order_price,
    status: order.status,
    createdAt: new Date(order.createdAt).toLocaleDateString(),
    deliveryAddress: order.delivery_address
      ? `${order.delivery_address.address}, ${order.delivery_address.city}, ${order.delivery_address.state}, ${order.delivery_address.country}`
      : "N/A",
    pickupAddress: order.pickup_address
      ? `${order.pickup_address.address}, ${order.pickup_address.city}, ${order.pickup_address.state}, ${order.pickup_address.country}`
      : "N/A",
    products: order.products,
  }));

  // ************** OPENING ORDER DETAIL PAGE ***************

  const handleRowClick = (params, event) => {
    if (!event.target.closest("select")) {
      const orderId = params.id;
      const selectedOrder = orders.find((o) => o._id === orderId);
      navigate(`/order/${orderId}`, {
        state: { order: selectedOrder, allOrders: orders },
      });
    }
  };

  // ************** STATUS CHANGE ***************

  const handleStatusChange = async (orderId, newStatus) => {
    try {
      const userData = { orderId, status: newStatus };
      await updateOrderStatus(userData);

      setStatusMap((prev) => ({
        ...prev,
        [orderId]: newStatus,
      }));

      fetchOrders();
      toast.success("Status updated successfully!");
    } catch (error) {
      toast.error("Failed to update status.");
      console.log("Error updating order status:", error);
    }
  };

  // ************** PRINT ORDERS ***************

  const handlePrint = () => {
    const selectedOrders = rows.filter((row) =>
      selectionModel.includes(row.id)
    );

    const printContent = [];

    // Build the content for the print window without opening it yet
    selectedOrders.forEach((order) => {
      const selectedOrder = order.products || [];

      selectedOrder.forEach((product) => {
        let orderHtml = `
          <div class="order detail-item">
            <h2>Order ID: ${order.id}</h2>
            <div><strong>Customer Name:</strong> ${order.userName}</div>
            ${
              order.message
                ? `<div><strong>Other Information:</strong> ${order.message}</div>`
                : ""
            }
            <table>
              <thead>
                <tr>
                  <th class="dress-details">Dress Details</th>
                  <th>Customer Size (Inches)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="dress-details">
                    <div class="detail-item">
                      <div><strong>Gender:</strong> ${product.gender}</div>
                      ${
                        product.dress_type
                          ? `<div><strong>Dress Type:</strong> ${product.dress_type}</div>`
                          : ""
                      }
                      ${
                        product.dress_fit_type
                          ? `<div><strong>Dress Fit:</strong> ${product.dress_fit_type}</div>`
                          : ""
                      }
                      ${
                        product.top_style
                          ? `<div><strong>Top Style:</strong> ${product.top_style}</div>`
                          : ""
                      }
                      ${
                        product.neck_style
                          ? `<div><strong>Neck Style:</strong> ${product.neck_style}</div>`
                          : ""
                      }
                      ${
                        product.sleeves_style
                          ? `<div><strong>Sleeves Style:</strong> ${product.sleeves_style}</div>`
                          : ""
                      }
                      ${
                        product.bottom_style
                          ? `<div><strong>Bottom Style:</strong> ${product.bottom_style}</div>`
                          : ""
                      }
                    </div>
                  </td>
                  <td>
                    <div class="size-details-container">
                      ${
                        product.sizeId
                          ? `
                      <div><strong>Neck:</strong> ${product.sizeId.neck}</div>
                      <div><strong>Shoulder:</strong> ${product.sizeId.shoulder}</div>
                      <div><strong>Sleeves:</strong> ${product.sizeId.sleeves}</div>
                      <div><strong>Arm Hole:</strong> ${product.sizeId.arm_hole}</div>
                      <div><strong>Bicep:</strong> ${product.sizeId.bicep}</div>
                      <div><strong>Wrist:</strong> ${product.sizeId.wrist}</div>
                      <div><strong>Chest:</strong> ${product.sizeId.chest}</div>
                      <div><strong>Back Chest:</strong> ${product.sizeId.back_chest}</div>
                      <div><strong>Waist:</strong> ${product.sizeId.waist}</div>
                      <div><strong>Hip:</strong> ${product.sizeId.hip}</div>
                      <div><strong>Top Length:</strong> ${product.sizeId.top_length}</div>
                      <div><strong>Bottom Length:</strong> ${product.sizeId.bottom_length}</div>
                    </div>
                     `
                          : "N/A"
                      }
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        `;

        // Add images if they exist
        if (
          Array.isArray(product.design_images) &&
          product.design_images?.length > 0
        ) {
          product.design_images.forEach((image) => {
            orderHtml += `
              <div class="order design-image">
                <h2>Order ID: ${order.id}</h2>
                <div><strong>Customer Name:</strong> ${order.userName}</div>
                ${
                  product.dress_type
                    ? `<div><strong>Dress Type:</strong> ${product.dress_type}</div>`
                    : ""
                }
                ${
                  product.dress_fit_type
                    ? `<div><strong>Dress Fit:</strong> ${product.dress_fit_type}</div>`
                    : ""
                }
                ${
                  product.top_style
                    ? `<div><strong>Top Style:</strong> ${product.top_style}</div>`
                    : ""
                }
                ${
                  product.neck_style
                    ? `<div><strong>Neck Style:</strong> ${product.neck_style}</div>`
                    : ""
                }
                ${
                  product.sleeves_style
                    ? `<div><strong>Sleeves Style:</strong> ${product.sleeves_style}</div>`
                    : ""
                }
                ${
                  product.bottom_style
                    ? `<div><strong>Bottom Style:</strong> ${product.bottom_style}</div>`
                    : ""
                }
                <img src=${image} alt="Design for Order ID ${
              order.id
            }" style="max-width: 100%; height: auto;"/>
              </div>
            `;
          });
        }

        // Add a page break after each product
        orderHtml += '<div style="page-break-after: always;"></div>';
        printContent.push(orderHtml);
      });
    });

    // Create a function to check if all images are loaded
    const loadImages = (htmlContent) => {
      return new Promise((resolve) => {
        const tempDiv = document.createElement("div");

        tempDiv.innerHTML = htmlContent;

        // Find all images in the generated HTML
        const imgs = tempDiv.getElementsByTagName("img");

        if (imgs?.length === 0) {
          resolve(); // No images to load
          return;
        }

        let loadedImages = 0;

        Array.from(imgs).forEach((img) => {
          img.onload = () => {
            loadedImages += 1;
            if (loadedImages === imgs?.length) {
              resolve(); // All images loaded
            }
          };
          img.onerror = () => {
            loadedImages += 1; // Count errored images as loaded
            if (loadedImages === imgs?.length) {
              resolve();
            }
          };
        });
      });
    };

    // Combine the content into a single string
    const finalContent = `
      <html>
        <head>
          <title>Print Orders</title>
          <style>
            body { font-family: Arial, sans-serif; }
            .order { margin-bottom: 20px; padding: 10px; border: 1px solid black; }
            .order h2 { margin: 0 0 10px; }
            .order div { margin-bottom: 5px; }
            table { width: 100%; border-collapse: collapse; margin-top: 10px; }
            th, td { border: 1px solid black; padding: 8px; text-align: left; }
            .dress-details { width: 50%; }
            .detail-item { display: flex; flex-direction: column; gap: 5px; } 
            img { max-width: 100%; height: auto; margin-top: 10px; }
            .design-image { page-break-before: always; }
            .size-details-container {
              display: grid;
              grid-template-columns: 1fr 1fr;
            }
          </style>
        </head>
        <body>
          <h1>Selected Orders</h1>
          ${printContent.join("")}
        </body>
      </html>
    `;

    // Load images and then open the print window
    loadImages(finalContent).then(() => {
      const printWindow = window.open("", "_blank");
      printWindow.document.write(finalContent);
      printWindow.document.close();
      printWindow.print();
    });
  };

  return (
    <>
      <ToastContainer autoClose={5000} position="top-right" />

      <div className="flex flex-col gap-3 bg-white py-5 px-3 rounded-lg border border-neutral">
        <div className="flex items-center justify-between h-10">
          <h3 className="text-xl font-medium">All Orders</h3>

          {selectionModel?.length > 0 && (
            <button
              onClick={handlePrint}
              className="bg-green text-white font-medium py-2 px-4 rounded"
            >
              Print
            </button>
          )}
        </div>

        {orders?.length > 0 ? (
          <div className="max-h-[660px]">
            <DataGrid
              rows={rows}
              columns={columns}
              checkboxSelection
              pageSizeOptions={[10, 25, 50]}
              onRowClick={handleRowClick}
              initialState={{ pagination: { paginationModel } }}
              onRowSelectionModelChange={(newSelection) => {
                setSelectionModel(newSelection);
              }}
              sx={{
                cursor: "pointer",
                "& .MuiDataGrid-row": {
                  backgroundColor: "#fafafa", // Row background color
                },
                "& .MuiDataGrid-cell:focus": {
                  outline: "none", // Remove focus outline
                },
                "& .MuiDataGrid-cell:hover": {
                  outline: "none", // Remove hover outline
                },
                "& .MuiDataGrid-columnHeader": {
                  backgroundColor: "#3c4348",
                  color: "white",
                },
                "& .MuiDataGrid-sortIcon": {
                  color: "white", // Sorting arrows color
                },
                "& .MuiDataGrid-menuIconButton": {
                  color: "white", // Three-dot menu button color
                },
              }}
            />
          </div>
        ) : (
          <div className="flex items-center justify-center text-lg">
            No orders yet!
          </div>
        )}
      </div>
    </>
  );
};

export default OrdersTable;
