import { Pie } from "react-chartjs-2";
import { Skeleton } from "@mui/material";
import { PiPackage } from "react-icons/pi";
import { RiProgress5Line } from "react-icons/ri";
import { BiCalendarCheck } from "react-icons/bi";
import { TbTruckDelivery } from "react-icons/tb";
import React, { useState, useEffect } from "react";
import { VscServerProcess } from "react-icons/vsc";
import "react-circular-progressbar/dist/styles.css";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { CircularProgressbar } from "react-circular-progressbar";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

import { getOrder } from "../../api";
import OrdersTable from "../../components/OrdersTable";

ChartJS.register(ArcElement, Tooltip, Legend);

const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const [allOrders, setAllOrders] = useState([]);
  const [pickupOrders, setPickupOrders] = useState(0);
  const [bookedOrders, setBookedOrders] = useState(0);
  const [arrivedOrders, setArrivedOrders] = useState(0);
  const [finishingOrders, setFinishingOrders] = useState(0);
  const [completedOrders, setCompletedOrders] = useState(0);
  const [processingOrders, setProcessingOrders] = useState(0);
  const [outForDeliveryOrders, setOutForDeliveryOrders] = useState(0);

  // ************** FETCH ORDERS ***************

  const fetchOrders = async () => {
    try {
      const data = await getOrder();

      const sortedOrders = data.orders.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

      setAllOrders(sortedOrders);
    } catch (error) {
      console.error("Error fetching orders:", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  // ************** ORDERS STATUS COUNT ***************

  useEffect(() => {
    setBookedOrders(
      allOrders?.filter((order) => order.status === "Booked")?.length || 0
    );
    setPickupOrders(
      allOrders?.filter((order) => order.status === "Scheduled for pickup")
        ?.length || 0
    );
    setArrivedOrders(
      allOrders?.filter((order) => order.status === "Arrived at Filato")
        ?.length || 0
    );
    setProcessingOrders(
      allOrders?.filter((order) => order.status === "In process")?.length || 0
    );
    setFinishingOrders(
      allOrders?.filter((order) => order.status === "Finishing")?.length || 0
    );
    setOutForDeliveryOrders(
      allOrders?.filter((order) => order.status === "Out for delivery")
        ?.length || 0
    );
    setCompletedOrders(
      allOrders?.filter((order) => order.status === "Delivered")?.length || 0
    );
  }, [allOrders]);

  // ************** ORDERS STATUS ***************

  const orderStatus = [
    {
      id: 0,
      title: "Booked orders",
      number: bookedOrders,
      icon: <BiCalendarCheck size={28} className="text-secondary" />,
    },
    {
      id: 1,
      title: "Orders in process",
      number: processingOrders,
      icon: <RiProgress5Line size={28} className="text-secondary" />,
    },
    {
      id: 2,
      title: "Finishing Orders",
      number: finishingOrders,
      icon: <VscServerProcess size={28} className="text-secondary" />,
    },
  ];

  const orderStatus2 = [
    {
      id: 0,
      title: "Orders scheduled for pickup",
      number: pickupOrders,
      icon: <TbTruckDelivery size={28} className="text-secondary" />,
    },
    {
      id: 1,
      title: "Orders arrived at Filato",
      number: arrivedOrders,
      icon: <HiOutlineOfficeBuilding size={28} className="text-secondary" />,
    },
  ];

  // ************** CHART DATA ***************

  const chartData = {
    labels: ["Booked", "In Process", "Finishing", "Completed"],
    datasets: [
      {
        label: "Order Status",
        data: [
          (bookedOrders / allOrders?.length) * 100 || 0,
          (processingOrders / allOrders?.length) * 100 || 0,
          (finishingOrders / allOrders?.length) * 100 || 0,
          (completedOrders / allOrders?.length) * 100 || 0,
        ],
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#00CF00"],
      },
    ],
  };

  return (
    <>
      {loading ? (
        <div className="grid lg:grid-cols-4 grid-cols-1 gap-4">
          <div className="flex flex-col gap-2 lg:col-span-3">
            <div className="grid lg:grid-cols-3 grid-cols-1 gap-3">
              {Array(3)
                .fill(null)
                .map((_, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between bg-white rounded-lg border border-neutral p-5"
                  >
                    <div className="flex flex-col gap-2">
                      <Skeleton
                        height={30}
                        width={30}
                        animation="wave"
                        variant="circular"
                      />
                      <Skeleton
                        height={20}
                        width={60}
                        animation="wave"
                        variant="rounded"
                      />
                      <Skeleton
                        height={15}
                        width={150}
                        animation="wave"
                        variant="rounded"
                      />
                    </div>

                    <Skeleton
                      height={50}
                      width={50}
                      animation="wave"
                      variant="circular"
                    />
                  </div>
                ))}
            </div>

            <div className="grid lg:grid-cols-2 grid-cols-1 gap-3">
              {Array(2)
                .fill(null)
                .map((_, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between bg-white rounded-lg border border-neutral p-5"
                  >
                    <div className="flex flex-col gap-2">
                      <Skeleton
                        height={30}
                        width={30}
                        animation="wave"
                        variant="circular"
                      />
                      <Skeleton
                        height={20}
                        width={60}
                        animation="wave"
                        variant="rounded"
                      />
                      <Skeleton
                        height={15}
                        width={150}
                        animation="wave"
                        variant="rounded"
                      />
                    </div>

                    <Skeleton
                      height={50}
                      width={50}
                      animation="wave"
                      variant="circular"
                    />
                  </div>
                ))}
            </div>

            <div className="flex flex-col bg-white rounded-lg border border-neutral p-5 gap-4">
              <Skeleton width={150} animation="wave" variant="rounded" />
              <Skeleton height={180} animation="wave" variant="rounded" />
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-4 bg-white rounded-lg border border-neutral p-5">
              <Skeleton
                height={55}
                width={55}
                animation="wave"
                variant="circular"
              />
              <div className="flex flex-col gap-1">
                <Skeleton
                  height={20}
                  width={100}
                  animation="wave"
                  variant="rounded"
                />

                <Skeleton width={70} animation="wave" variant="text" />
              </div>
            </div>

            <div className="flex items-center gap-4 bg-white rounded-lg border border-neutral p-5">
              <div className="flex flex-col">
                <Skeleton width={100} animation="wave" variant="text" />
                <Skeleton width={70} animation="wave" variant="text" />
              </div>
            </div>

            <div className="flex flex-col items-center gap-4 bg-white rounded-lg border border-neutral p-5">
              <Skeleton
                width={120}
                animation="wave"
                variant="rounded"
                className="self-start"
              />

              <div className="flex flex-col items-center">
                <div className="flex items-center gap-2">
                  <Skeleton width={60} animation="wave" variant="text" />
                  <Skeleton width={90} animation="wave" variant="text" />
                </div>

                <div className="flex items-center gap-2">
                  <Skeleton width={70} animation="wave" variant="text" />
                  <Skeleton width={50} animation="wave" variant="text" />
                </div>

                <Skeleton width={60} animation="wave" variant="text" />
              </div>

              <Skeleton
                width={150}
                height={150}
                animation="wave"
                variant="circular"
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="grid lg:grid-cols-4 grid-cols-1 gap-4">
          <div className="flex flex-col gap-1 lg:col-span-3">
            <div className="grid lg:grid-cols-3 grid-cols-1 gap-3">
              {orderStatus?.map((item, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between bg-white rounded-lg border border-neutral p-5 gap-1"
                >
                  <div className="flex flex-col gap-1">
                    <span className="mb-1">{item.icon}</span>
                    <span className="font-bold text-xl">{item.number}</span>
                    <span className="text-secondary">{item.title}</span>
                  </div>
                  <div className="w-16 h-16 ">
                    <CircularProgressbar
                      value={item.number}
                      text={`${item.number}%`}
                      styles={{
                        path: {
                          stroke: `#069fa5`,
                        },
                        text: {
                          fill: "#069fa5",
                        },
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>

            <div className="grid lg:grid-cols-2 grid-cols-1 gap-3">
              {orderStatus2?.map((item, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between bg-white rounded-lg border border-neutral p-5 gap-1"
                >
                  <div className="flex flex-col gap-1">
                    <span className="mb-1">{item.icon}</span>
                    <span className="font-bold text-xl">{item.number}</span>
                    <span className="text-secondary">{item.title}</span>
                  </div>
                  <div className="w-16 h-16 ">
                    <CircularProgressbar
                      value={item.number}
                      text={`${item.number}%`}
                      styles={{
                        path: {
                          stroke: `#069fa5`,
                        },
                        text: {
                          fill: "#069fa5",
                        },
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
            <OrdersTable fetchOrders={fetchOrders} orders={allOrders} />
          </div>

          <div className="flex flex-col gap-2">
            <div className="flex bg-white rounded-lg border border-neutral px-5 py-5 gap-3 items-center">
              <span className="p-4 bg-light border rounded-full">
                <PiPackage size={30} />
              </span>

              <div className="flex flex-col">
                <span className="font-bold text-xl">Total Orders</span>
                <span className="text-secondary font-medium text-lg">
                  {allOrders?.length}
                </span>
              </div>
            </div>

            <div className="flex flex-col bg-white rounded-lg border border-neutral px-5 py-5">
              <div className="flex items-center gap-2">
                <span className="text-secondary font-medium text-2xl">
                  {outForDeliveryOrders}
                </span>
                <span className="font-medium">orders</span>
              </div>
              <span className="font-medium">are out for delivery</span>
            </div>

            <div className="flex flex-col gap-3 bg-white rounded-lg border border-neutral p-5">
              <h3 className="text-lg font-bold">Order History</h3>
              <Pie data={chartData} />

              <div className="flex flex-col">
                <span className="font-medium">Completed Orders</span>
                <span>{completedOrders}</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;
