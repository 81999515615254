import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

import { updateOrderStatus, deleteOrder } from "../../api";

const OrderDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { order, allOrders } = location.state || {};

  const [loading, setLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  // ************** OPEN DIALOGUE ***************

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  // ************** CLOSE DIALOGUE ***************

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  // ************** CANCEL ORDER ***************

  const handleCancelOrder = async (orderId) => {
    setLoading(true);

    try {
      await deleteOrder(orderId);
      setLoading(false);
      setIsDialogOpen(false);
      navigate("/");
    } catch (error) {
      setLoading(false);
      toast.error("Error deleting order!");
      console.error("Failed to delete order", error);
    }
  };

  // ************** STATUS CHANGE ***************

  const [statusMap, setStatusMap] = useState(
    allOrders?.reduce((acc, curr) => {
      acc[curr._id] = curr.status;
      return acc;
    }, {}) || {}
  );

  const handleStatusChange = async (orderId, newStatus) => {
    try {
      const userData = { orderId, status: newStatus };
      await updateOrderStatus(userData);

      setStatusMap((prev) => ({
        ...prev,
        [orderId]: newStatus,
      }));

      toast.success("Status updated successfully!");
    } catch (error) {
      toast.error("Failed to update status.");
      console.log("Error updating order status:", error);
    }
  };

  // ************** IMAGE VIEW MODAL ***************

  const ImageModal = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center mt-16">
      <img
        src={selectedImage}
        alt="Selected Design"
        className="max-w-full max-h-[70vh] object-contain"
      />
      <button
        onClick={() => setSelectedImage(null)}
        className="absolute top-3 right-3 text-black font-bold bg-white rounded-full w-10 h-10"
      >
        X
      </button>
    </div>
  );

  return (
    <>
      <ToastContainer autoClose={5000} position="top-right" />
      <div className="grid lg:grid-cols-3 grid-cols-1 gap-4">
        <div className="lg:col-span-2 flex flex-col gap-3">
          <div
            className="flex md:flex-row flex-col md:items-center md:justify-between 
            bg-white border rounded-lg p-4 md:gap-0 gap-3"
          >
            <h1 className="font-medium md:text-2xl text-xl">Order Detail</h1>

            <button
              onClick={openDialog}
              className="text-white font-medium rounded md:w-fit w-full px-3 py-2 bg-red-600"
            >
              Cancel Order
            </button>

            {isDialogOpen && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                <div className="bg-white p-6 rounded shadow-lg lg:w-1/3 md:w-2/3 w-3/3">
                  <h2 className="text-lg font-medium mb-3">
                    Confirm Cancellation
                  </h2>
                  <p>Are you sure you want to cancel the order?</p>

                  <div className="mt-4 flex justify-end">
                    <button
                      className="bg-gray-400 text-white rounded px-4 py-2 mr-2"
                      onClick={closeDialog}
                    >
                      No
                    </button>

                    <button
                      disabled={loading}
                      onClick={() => handleCancelOrder(order._id)}
                      className={`text-white font-medium rounded w-fit px-3 py-2
                      ${
                        loading
                          ? "bg-secondary cursor-not-allowed"
                          : "bg-red-600"
                      } `}
                    >
                      <span>
                        {loading ? "Cancelling Order..." : "Yes, Cancel"}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="grid md:grid-cols-2 grid-cols-1 md:gap-y-1 gap-y-2 bg-white border rounded-lg p-4">
            <div className="flex md:flex-row flex-col md:items-center md:gap-2 gap-0">
              <h1 className="font-medium">Order ID:</h1>
              <span>{order._id}</span>
            </div>

            <div className="flex md:flex-row flex-col md:items-center md:gap-2 gap-0">
              <span className="font-medium">Order placed on:</span>
              <span>
                {new Date(order.createdAt).toLocaleDateString("en-US", {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </span>
            </div>

            <div className="flex items-center gap-2">
              <span className="font-medium">Total Price:</span>
              <span>{order.order_price}</span>
            </div>

            <div className="flex items-center gap-2">
              <span className="font-medium">Payment Mode:</span>
              <span>{order.payment_mode}</span>
            </div>

            <div className="flex md:flex-row flex-col items-center gap-2">
              <span className="font-medium">Order Status:</span>
              <select
                value={statusMap[order._id]}
                onClick={(e) => e.stopPropagation()}
                className="h-9 border rounded-md cursor-pointer"
                onChange={(e) => handleStatusChange(order._id, e.target.value)}
              >
                <option value="Booked">Booked</option>
                <option value="Scheduled for pickup">
                  Scheduled for pickup
                </option>
                <option value="Arrived at Filato">Arrived at Filato</option>
                <option value="In process">In process</option>
                <option value="Finishing">Finishing</option>
                <option value="Out for delivery">Out for delivery</option>
                <option value="Delivered">Delivered</option>
              </select>
            </div>
          </div>

          {order.products?.map((item, index) => (
            <div
              key={index}
              className="flex flex-col gap-2 bg-white border rounded-lg p-4"
            >
              <span className="font-medium text-xl underline underline-offset-2 mb-1">
                Dress {index + 1}
              </span>

              {item.service_type && (
                <div className="flex md:flex-row flex-col md:items-center md:gap-2 gap-0">
                  <span className="font-medium">Buy for me:</span>
                  <a
                    target="_blank"
                    href={item.service_type}
                    rel="noopener noreferrer"
                    className="text-blue-800 hover:text-red-500 truncate"
                  >
                    {item.service_type}
                  </a>
                </div>
              )}

              <div className="flex flex-col space-y-1">
                <span className="text-lg font-medium">Dress Detail</span>

                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-y-1">
                  <div className="flex items-center gap-2">
                    <span className="font-medium">Gender:</span>
                    <span>{item.gender}</span>
                  </div>

                  {item.dress_type && (
                    <div className="flex items-center gap-2">
                      <span className="font-medium">Dress Type:</span>
                      <span>{item.dress_type}</span>
                    </div>
                  )}

                  {item.dress_fit_type && (
                    <div className="flex items-center gap-2">
                      <span className="font-medium">Dress Fit:</span>
                      <span>{item.dress_fit_type}</span>
                    </div>
                  )}

                  {item.top_style && (
                    <div className="flex items-center gap-2">
                      <span className="font-medium">Top Style:</span>
                      <span>{item.top_style}</span>
                    </div>
                  )}

                  {item.bottom_style && (
                    <div className="flex items-center gap-2">
                      <span className="font-medium">Bottom Style:</span>
                      <span>{item.bottom_style}</span>
                    </div>
                  )}

                  {item.neck_style && (
                    <div className="flex items-center gap-2">
                      <span className="font-medium">Neck Style:</span>
                      <span>{item.neck_style}</span>
                    </div>
                  )}

                  {item.sleeves_style && (
                    <div className="flex items-center gap-2">
                      <span className="font-medium">Sleeves Style:</span>
                      <span>{item.sleeves_style}</span>
                    </div>
                  )}
                </div>
              </div>

              <div className="flex flex-col space-y-1 mt-3">
                <span className="text-lg font-medium">
                  Size Detail (Inches)
                </span>

                {item.sizeId ? (
                  <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-1 gap-y-1">
                    <div className="flex items-center gap-2">
                      <span className="font-medium">Neck:</span>
                      <span>{item.sizeId.neck}</span>
                    </div>

                    <div className="flex items-center gap-2">
                      <span className="font-medium">Shoulder:</span>
                      <span>{item.sizeId.shoulder}</span>
                    </div>

                    <div className="flex items-center gap-2">
                      <span className="font-medium">Sleeves:</span>
                      <span>{item.sizeId.sleeves}</span>
                    </div>

                    <div className="flex items-center gap-2">
                      <span className="font-medium">Arm Hole:</span>
                      <span>{item.sizeId.arm_hole}</span>
                    </div>

                    <div className="flex items-center gap-2">
                      <span className="font-medium">Bicep:</span>
                      <span>{item.sizeId.bicep}</span>
                    </div>

                    <div className="flex items-center gap-2">
                      <span className="font-medium">Wrist:</span>
                      <span>{item.sizeId.wrist}</span>
                    </div>

                    <div className="flex items-center gap-2">
                      <span className="font-medium">Chest:</span>
                      <span>{item.sizeId.chest}</span>
                    </div>

                    <div className="flex items-center gap-2">
                      <span className="font-medium">Back Chest:</span>
                      <span>{item.sizeId.back_chest}</span>
                    </div>

                    <div className="flex items-center gap-2">
                      <span className="font-medium">Waist:</span>
                      <span>{item.sizeId.waist}</span>
                    </div>

                    <div className="flex items-center gap-2">
                      <span className="font-medium">Hip:</span>
                      <span>{item.sizeId.hip}</span>
                    </div>

                    <div className="flex items-center gap-2">
                      <span className="font-medium">Top Length:</span>
                      <span>{item.sizeId.top_length}</span>
                    </div>

                    <div className="flex items-center gap-2">
                      <span className="font-medium">Bottom Length:</span>
                      <span>{item.sizeId.bottom_length}</span>
                    </div>
                  </div>
                ) : (
                  "N/A"
                )}
              </div>

              {item.message && (
                <div className="flex flex-col">
                  <span className="font-medium">Additional Information:</span>
                  <span>{item.message}</span>
                </div>
              )}

              {item.design_images?.length > 0 && (
                <div className="flex flex-col gap-1">
                  <span className="font-medium">Reference Design:</span>
                  <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 items-center lg:gap-4 gap-2">
                    {item.design_images?.map((image, index) => (
                      <img
                        key={index}
                        src={image}
                        width={120}
                        height={120}
                        alt="dress design"
                        onClick={() => setSelectedImage(image)}
                        className="rounded w-full h-full max-h-40 object-cover"
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="flex flex-col gap-3">
          <h1 className="font-medium md:text-2xl text-xl bg-white border rounded-lg py-5 px-4">
            Customer Details
          </h1>

          <div className="flex flex-col gap-1 bg-white border rounded-lg p-4">
            <h1 className="font-medium md:text-xl text-lg mb-2">
              Personal Detail
            </h1>

            <div className="flex items-center gap-2">
              <span className="font-medium">Name:</span>
              <span>{order.userId.name}</span>
            </div>

            <div className="flex items-center gap-2">
              <span className="font-medium">Gender:</span>
              <span>{order.userId.gender}</span>
            </div>

            <div className="flex items-center gap-2">
              <span className="font-medium">Email:</span>
              <span>{order.userId.email}</span>
            </div>

            <div className="flex items-center gap-2">
              <span className="font-medium">Phone number:</span>
              <span>+{order.userId.phone_number}</span>
            </div>
          </div>

          <div className="flex flex-col gap-2 bg-white border rounded-lg p-4">
            <h1 className="font-medium md:text-xl text-lg mb-2">
              Address Detail
            </h1>

            <div className="flex flex-col">
              <span className="font-medium">Pickup Address:</span>
              <span>
                {order.pickup_address
                  ? `${order.pickup_address.address},
                ${order.pickup_address.city},
                ${order.pickup_address.state},
                ${order.pickup_address.country}`
                  : "N/A"}
              </span>
            </div>

            <div className="flex flex-col">
              <span className="font-medium">Delivery Address:</span>
              <span>
                {order.delivery_address
                  ? `${order.delivery_address.address},
                ${order.delivery_address.city},
                ${order.delivery_address.state},
                ${order.delivery_address.country}`
                  : "N/A"}
              </span>
            </div>
          </div>

          {order.payment_mode === "Bank Transfer" && (
            <div className="flex flex-col gap-2 bg-white border rounded-lg p-4">
              <span className="font-medium md:text-xl text-lg">
                Bank Transfered Payment:
              </span>
              <img
                alt="screenshoot"
                className="w-auto h-auto"
                src={order.payment_evidence}
                onClick={() => setSelectedImage(order.payment_evidence)}
              />
            </div>
          )}
        </div>
      </div>

      {selectedImage && <ImageModal />}
    </>
  );
};

export default OrderDetails;
